import { createTheme } from "@material-ui/core/styles";
import values from "./breakpoints/values";
import colorName from "color-name";

function isDarkColor(hexColor) {
  var color = hexColor.charAt(0) === "#" ? hexColor.substring(1, 7) : hexColor;
  var r = parseInt(color.substring(0, 2), 16); // hexToR
  var g = parseInt(color.substring(2, 4), 16); // hexToG
  var b = parseInt(color.substring(4, 6), 16); // hexToB
  var uicolors = [r / 255, g / 255, b / 255];
  var c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.179 ? false : true;
}

const theme = createTheme({
  palette: {
    getColor: (colorName) => {
      for (const property in theme.palette) {
        if (theme.palette[property][colorName]) {
          return theme.palette[property][colorName];
        }
      }
      return "transparent";
    },
    themeColor: {
      primary:
        JSON.parse(localStorage.getItem("townTheme"))?.primary_color ??
        "#f98c10",
      secondary:
        JSON.parse(localStorage.getItem("townTheme"))?.secondary_color ??
        "#7fc035",
      sign_up_background_color:
        JSON.parse(localStorage.getItem("townTheme"))
          ?.sign_up_background_color ?? "#FCEEE0",
      primary_contrast: isDarkColor(
        JSON.parse(localStorage.getItem("townTheme"))?.primary_color ??
          "#f98c10"
      )
        ? "white"
        : "black",
      secondary_contrast: isDarkColor(
        JSON.parse(localStorage.getItem("townTheme"))?.secondary_color ??
          "#7fc035"
      )
        ? "white"
        : "black",
    },
    dark: {
      black: "#000000",
      gray: "#606060",
      blue: "#00222E",
      red: "#EA4335",
      green: "#638E36",
      imageBackground: "#484544",
    },
    light: {
      gray: "#777777",
      grey: "#e3e3e3",
      blue: "#009fff",
      lightGray: "#E1E1E1",
      orange: "#FCC07C",
      orange2: "#FFAB5C",
      borderGray: "#D9D9D9",
      imageBackground: "#F1EEED",
    },
    neutral: {
      cosmicCobalt1: "#2C438C",
    },
    positive: {
      wintergreenDream1: "#2B6857",
    },
    correction: {
      smokyTopaz1: "#A03E47",
    },
    tapintoBranding: {
      tapintoOrange: "#f98c10",
      tapintoOrange2: "#F89035",
      tapintoGreen: "#7FC035",
      greenVariationDark: "#628F30",
      darkerGreen: "#4C9100",
      lightOrange: "#FCEEE0",
    },
    miscellaneous: {
      orange: "#f98c10",
      white: "#ffffff",
      black: "#000000",
      blueLink: "#0283a0",
      grayDivider: "#d8d8d8",
      red: "#EB5757",
      lightRed: "#ffd2d2",
      twitterBlue: "#00ACEE",
      instagramPink: "#E1306C",
      defaultRed: "red",
      green: "green",
      redForPrintIcon: "#e64c34",
      lightBlack: "#171717",
      backgroundImageGray: "#CEC6C1",
    },
    grayVariation: {
      grayForSubTitle: "#606060",
      grayForCredits: "#777777",
      grayForBorder: "#C4C4C4",
      grayForBackground: "#F2F2F2",
      grayForDarkBackground: "#2c2c2c",
      grayForDisabledField: "#e6e6e6",
      grayForBreadcrumbs: "#666666",
      grayForEventCategories: "#8e8b8b",
      grayForStripeRows: "#f1f1f1",
      grayForBorders: "#AAAAAA",
      grayForBordersLight: "#EFEFEF",
      secondaryGrey: "#454545",
      grayLightFooter: "#333333",
      grayHoverButton: "#EEEEEE",
      grayForSubLabel: "#9C9C9C",
      grayForCardBackground: "#E9E8E6",
      grayForContainerBackground: "#F6F6F6",
      grayForItemListBackground: "#E5E5E5",
    },
    facebook: {
      facebookBlue: "#475993",
      facebookBlueDarkerVariant: "#1b74e4",
      lightBlue: "#F2F2F2",
    },
    cms: {
      primary: {
        lighter: "#FFE8CD",
        main: "#F98C10",
        textContrast: "white",
      },
      secondary: {
        lighter: "#CEE9AF",
        main: "#7FC035",
        textContrast: "white",
      },
      gray: {
        lighter: "#F5F5F5",
        main: "#878787",
        background: "#FAFAFA",
      },
      info: {
        main: "#0288D1",
      },
      warning: {
        main: "#FCDE44",
      },
      error: {
        lighter: "#FD3D0066",
        light: "#FE835C",
        main: "#FD3D00",
      },
      black: {
        main: "#303030",
      },
    },
  },
  breakpoints: {
    values: values,
  },
  mastheadHeights: {
    reduced_mobile: "68px",
    reduced_mobile_overall: "88px",
    mobile: "140px",
    desktop: "240px",
    overall_desktop: "240px",
    overall_mobile: "96px",
  },
  menuSize: { default: "385px", mobile: "345px" },
  colorName: colorName,
  fontFamily: {
    primary: "Montserrat, sans-serif",
    secondary: "Open Sans, sans-serif",
  },
});

const colorTypes = [
  "dark",
  "light",
  "neutral",
  "positive",
  "correction",
  "tapintoBranding",
  "miscellaneous",
  "grayVariation",
  "facebook",
];
// Color name reference array, primarily used in PropTypes (e.g. `PropTypes.oneOf(colorReference)` or `PropTypes.oneOf([...colorReference, 'transparent'])`)
export const colorReference = colorTypes.reduce(
  (acc, type) => [...acc, ...Object.keys(theme?.palette[type])],
  []
);

export default theme;
