const promotionsLabels = {
  SECTION_STATUS: {
    SUCCESS: "Completed",
    ERROR: "There are items that require your attention",
  },
  SUMMARY_ACTIONS: {
    APPROVE: "Approve",
    APPROVING: "Approving...",
    APPROVED: "Approved",
    DISAPPROVE: "Disapprove",
    SEND_TO_GAM: "Send to GAM",
    SENDING: "Sending...",
    COMPLETE: "Complete",
    COMPLETED: "Completed",
    REOPEN: "Reopen",
  },
  RECEIVES: "Receives",
  SEND_PREVIEW_E_BLAST: "Send preview E-blast",
  CREATIVES: {
    SIZE: "Size",
    TOWNS: "Towns",
    UPLOADED_FILE_SUCCESS: "Uploaded file successfully",
    CHOOSE_VIDEO_AD: "Choose video ad",
    CREATIVE_NAME: "Creative name",
    VIDEO_NAME: "Video name",
    RECOMMENDATION_VIDEO_TITLE: "Video recommendations",
  },
  BILLING_INFO: {
    SPLITS: "Splits",
    SELECT: "Select",
    NO_TOWN_OR_PARTNER_SELECTED: "No town/partner selected",
  },
  DIALOGS: {
    DESKTOP_LEADERBOARD_MESSAGE:
      "The Desktop Leaderboard needs a Mobile Leaderboard too, do you want to continue?",
    MOBILE_LEADER_BOARD_MESSAGE:
      "The Mobile Leaderboard needs a Desktop Leaderboard too, do you want to continue?",
  },
  SPONSORS: {
    RECOMMENDATION_IMAGE_TITLE: "Sponsor image recommendations",
  }
};

export default promotionsLabels;
